<template>
    <div>
        <header-admin-pages
            title="Блоги"
            :counter="getBloggers.length"
            :button="headerButton"/>

        <div class="block_search">
          <input ref="inputTeg" @keyup.enter="clickSearch" v-model="search" type="text" class="input blue search-input" placeholder="Поиск">

            <select name="status" v-model="status" class="input blue" required>
                <option :value="null">
                    -
                </option>
                <option v-for="(status,index) in getStatusBlogs" :value="status.code" :key="`status${index}`">
                    {{ status.title }}
                </option>
            </select>
          <button @click="clickSearch" class="btn-blue"> Поиск </button>
        </div>

        <article class="accordion-block" :class="{'active': indexElement===i && show}"
            v-for="(item, i) in getBloggers" :key="`member${i}`">
            <header @click="toggle(item, i)">
            <p>{{ item.fullName }}</p>
            <i class="accordion-block__details" :class="{'active' : indexElement===i && show}"></i>
            </header>
            <transition name="accordion">
            <div class="accordion-block__body" v-show="indexElement===i && show">
                <table>
                    <tr>
                        <td>Заголовок</td>
                        <td>Дата</td>
                        <td>Статус</td>
                        <td class="drop-icon"></td>
                    </tr>
                    <tr v-for="(blog, i) of getSpecificMembers" :key="'blog'+i">
                        <td>{{ blog.title }}</td>
                        <td>{{ blog.date }}</td>
                        <td>{{ blog.status}}</td>
                        <td class="drop-icon">
                        <drop-list @clickItem="clickItem"
                                    :id-item="blog.id"
                                    :edit-link="`blog_create/${item.id}/${blog.id}`"/>
                        </td>
                    </tr>
                </table>

            </div>
            </transition>
        </article>
    </div>
</template>
<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'
export default {
  name: 'BlogsList',

  data () {
    return {
      show: false,
      indexElement: 0,
      headerButton: {
        title: 'Добавить блог',
        link: '/admin/blog_create'
      },
      search: '',
      status: null
    }
  },

  created () {
    this.$store.dispatch('setAllMembersBlog')
    if (this.$store.getters.getStatusBlogs.length === 0) this.$store.dispatch('setStatusBlogs')
  },

  methods: {
    toggle (item, index) {
      let param = ''
      if (this.search) {
        param = '?search=' + this.search

        if (this.status) {
          param += '&nado=' + this.status
        }
      } else if (this.status) {
        param = '?nado=' + this.status
      }
      if (this.indexElement === index && this.show === true) {
        this.show = false
      } else {
        this.$store.dispatch('setSpecificMembersBlogs', { id: item.id, param: param })
        this.show = true
      }
      this.indexElement = index
    },
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить новость?')
        if (consent) {
          this.$store.dispatch('deleteBlogAdmin', { id: value.id, allBlog: this.getBloggers[this.indexElement].id })
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailBlogAdmin', { post: value.id, member: this.getBloggers[this.indexElement].id })
      }
    },
    clickSearch () {
      let param = ''
      if (this.search) {
        param = '?search=' + this.search

        if (this.status) {
          param += '&nado=' + this.status
        }
      } else if (this.status) {
        param = '?nado=' + this.status
      }

      this.$store.dispatch('setAllMembersBlog', param)
    }
  },

  computed: {
    getBloggers () {
      return this.$store.getters.getAllMembersBlogs
    },
    getSpecificMembers () {
      return this.$store.getters.getSpecificMembersBlogs
    },
    getStatusBlogs () {
      return this.$store.getters.getStatusBlogs
    }
  },
  watch: {
    getBloggers () {
      this.show = false
    }
  },
  components: {
    HeaderAdminPages,
    DropList
  }
}
</script>
<style lang="scss" scoped>
    .search-input {
        margin-right: 1rem;
    }

.accordion-block {
    border: .06rem solid #E5E8EE;
    border-radius: 0.5rem;
    margin-bottom: 1.875rem;
    font-size: .875rem;
    line-height: 1.57;

    &.active {
      box-shadow: 0 .94rem 1.56rem rgba(189, 205, 221, 0.35);
    }

    header {
      color: #1F3245;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 1.875rem;

      & p { margin-right: 5rem; }
      & i {
        border: .1875rem solid #9D9D9D;
        border-width: 0 .1875rem .1875rem 0;
        border-radius: .0625rem;
        display: inline-block;
        padding: .1875rem;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: .0625rem;
      }
      .active {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    }
  }
.content-sb{
    margin-bottom: 2rem;
}
.accordion-block__body{
    padding: 0 1.875rem;
}
  table{
    margin-top: 1rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;
        a {
          color: #246CB7;
        }
        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }
      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
table:last-child tr{
    border-bottom: 0;
}
.block_search{
  display: flex;
  margin-bottom: 2rem;
  button{
    margin-left: 1rem;
  }
  & :nth-child(3){
    background: #ffffff;
    color: #246CB7;
  }
}
  //
@media screen and (max-width: 768px){
}
@media screen and (max-width: 420px){
  .accordion-block{
    header{
      padding: 1.25rem 1.38rem;
      p{
        margin-right: .125rem;
      }
    }
  }
}
</style>
